import type React from 'react';
import { Link } from '@remix-run/react';
import clsx from 'clsx';
import { twMerge } from 'tailwindMerge.config';
import { PtnIcon } from '~/components/ui/PtnIcon';

import type { IconOptions } from '~/utils/icon';

interface LinkProps {
  to: string;
  disabled?: boolean;
  target?: '' | '_brank';
  className?: string;
  icon?: IconOptions;
  children: React.ReactNode;
  onClick?: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.TouchEvent<HTMLAnchorElement>,
  ) => void;
}

export const PtnLink: React.FC<LinkProps> = ({
  children,
  to,
  disabled = false,
  target = '',
  className = '',
  icon = null,
  onClick = () => {},
}) => {
  const isExternal = to.startsWith('https://');
  const openingTarget = isExternal && target === '' ? '_blank' : target;

  const baseStyle = clsx(
    'flex items-center text-blue-20 h-min rounded-4 transition-opacity',
    'hover:opacity-40',
    'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-30',
    'visited:text-blue-20',
  );

  const disabledStyle = 'opacity-40 cursor-not-allowed';

  const handleClick = (
    e:
      | React.MouseEvent<HTMLAnchorElement>
      | React.TouchEvent<HTMLAnchorElement>,
  ) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    onClick(e);
  };

  if (isExternal) {
    return (
      <a
        href={to}
        target={openingTarget}
        rel={openingTarget === '_blank' ? 'noopener noreferrer' : ''}
        className={twMerge(baseStyle, disabled && disabledStyle, className)}
        onClick={handleClick}
      >
        {icon && icon.position === 'prefix' && (
          <PtnIcon
            style={{ width: `${icon.size}px`, height: `${icon.size}px` }}
            className="mr-0.5"
            iconName={icon.name}
            type={icon.type}
          />
        )}
        {children}
        {icon && icon.position === 'suffix' && (
          <PtnIcon
            style={{ width: `${icon.size}px`, height: `${icon.size}px` }}
            className="ml-0.5"
            iconName={icon.name}
            type={icon.type}
          />
        )}
      </a>
    );
  }

  return (
    <Link
      to={to}
      className={twMerge(baseStyle, disabled ? disabledStyle : '', className)}
      onClick={handleClick}
    >
      {icon && icon.position === 'prefix' && (
        <PtnIcon
          style={{ width: `${icon.size}px`, height: `${icon.size}px` }}
          className="mr-0.5"
          iconName={icon.name}
          type={icon.type}
        />
      )}
      {children}
      {icon && icon.position === 'suffix' && (
        <PtnIcon
          style={{ width: `${icon.size}px`, height: `${icon.size}px` }}
          className="ml-0.5"
          iconName={icon.name}
          type={icon.type}
        />
      )}
    </Link>
  );
};
